import tw, { css } from "twin.macro";
import { graphql, Link } from "gatsby";
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
} from "gatsby-plugin-image";
import SwiperCore, {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ScheduleBanner from "../components/ScheduleBanner";
import {
	AppStyles,
	Body,
	H2,
	TextLink,
} from "../styles/AppStyles.styles.tw";

// import AmenitiesImage from "../images/photos/Kitchen.png";
import BackgroundPattern from "../images/patterns/Green_Pattern.png";

// Swiper Imports
SwiperCore.use([
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Autoplay,
]);

const backgroundPattern = css`
	background-image: url(${BackgroundPattern});
`;

function Gallery({ data }: any): JSX.Element {
	// const images = data.images || {};
	// const gallery = [];
	// for (const image of images.edges) {
	// 	const gatsbyData = getImage(image.node);
	// 	const imageObject = {
	// 		image: gatsbyData,
	// 		name: image.node.base,
	// 	};
	// 	gallery.push(imageObject);
	// }

	return (
		<Layout title="gallery">
			<SEO
				title={data.sanityGallery.pageTitle}
				description={data.sanityGallery.pageDescription}
				keywords={data.sanityGallery.keywords.join(", ")}
			/>
			<AppStyles>
				<section
					css={[
						tw`px-4 py-16 text-center text-white bg-center bg-cover bg-pine lg:px-0 lg:py-24`,
						backgroundPattern,
					]}
				>
					<div tw="space-y-4">
						<H2>{data.sanityGallery.header}</H2>
						<Body>
							{data.sanityGallery.body}{" "}
							<a href={data.sanityGallery.linkURL}>
								<TextLink tw="text-white inline">
									{data.sanityGallery.linkText}
								</TextLink>
							</a>
						</Body>
					</div>
					<div tw="mx-auto container flex justify-center items-center py-4 lg:py-16">
						<Swiper
							slidesPerView={1}
							navigation
							pagination={{ clickable: true }}
						>
							{data.sanityGallery.imageGallery.map(
								(image: any, i: number) => (
									<SwiperSlide key={i}>
										<div tw="w-full h-full pb-16 pt-16 px-10 lg:p-16 flex justify-center items-center">
											<GatsbyImage
												image={
													getImage(
														image.asset.gatsbyImageData
													) as IGatsbyImageData
												}
												alt={image.alt}
												tw="w-full h-full flex justify-center items-center"
											/>
										</div>
									</SwiperSlide>
								)
							)}
						</Swiper>
					</div>
				</section>
				<ScheduleBanner />
			</AppStyles>
		</Layout>
	);
}

export default Gallery;

export const query = graphql`
	{
		sanityGallery {
			body
			header
			linkText
			linkURL
			keywords
			pageDescription
			pageTitle
			imageGallery {
				asset {
					gatsbyImageData(placeholder: BLURRED)
					url
				}
			}
		}
	}
`;
